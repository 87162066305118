<template>
  <div class="avoir-facture-partiel">
    <font-awesome-icon
      icon="file-medical"
      class="icon-style-color mr-1 ml-2"
      title="Avoir sur Facture"
      v-if="permission"
      @click.prevent="openModal('AvoirFacture' + index)"
    />

    <b-modal
      no-close-on-backdrop
      title="Modifier list des produits"
      :ref="'AvoirFacture' + index"
      :id="'AvoirFacture' + index"
      :hide-footer="true"
      :hide-header="true"
      size="sm"
      modal-class="cutsom-modal-bootstrap custom-modal-template-frais-cdg custom-modal-template-w-60-p"
    >
      <div class="hader mb-2">
        <div class="titleSetting text-aligne-center col-10">
          Création Facture(s) Avoir(s) N° : {{ item.num }}
        </div>
        <div
          class="iconClose col-1"
          @click.prevent="hideModal('AvoirFacture' + index)"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <div class="sumilation-template-block-style mb-1" id="hmt_block_id">
        <form @submit.prevent="handleCreateAvoir">
          <b-col class="col-12 block-product-simulation mt-5">
            <b-row class="fields-input">
              <!-- <b-col class="col-5"
                </b-col> -->
              <!-- <hr class="separate-line col-10 m-0 mb-2 ml-4" /> -->
            </b-row>
          </b-col>
          <b-col class="col-12 block-product-simulation scrol-block-product">
            <b-row class="mb-3">
              <b-col class="col-5 text-center"> <b>Nom</b></b-col>
              <b-col class="col-2 text-center"
                ><b>{{ annex ? 'Qté' : 'PU HT' }} </b></b-col
              >
              <b-col class="col-2 text-center"><b>Unité</b></b-col>
              <b-col class="col-2 text-center"
                ><b>{{ annex ? 'PU HT' : 'Qté' }}</b></b-col
              >
            </b-row>
            <b-row class="mb-3"
              ><hr class="separate-line col-11 m-0 mb-2 ml-4"
            /></b-row>

            <b-row
              class="fields-input"
              v-for="(product, index) in getProduitsFactureAvoir"
              :key="'product' + index"
              :class="computedSocieteColor(product)"
            >
              <b-col
                class="col-12 text-center mb-2 font-weight-bold mt-2 "
                v-if="computedSociete(product, index)"
                >{{ product.description }}
              </b-col>

              <b-col class="col-5"
                ><b-form-group
                  label=""
                  label-for="title-produt"
                  class="input-modal-champ"
                >
                  <b-form-input
                    autocomplete="off"
                    id="title-produt"
                    v-model="product.nom"
                    required
                    disabled
                  ></b-form-input> </b-form-group
              ></b-col>
              <b-col class="col-2"
                ><b-form-group
                  label=""
                  :label-for="annex ? 'qte-product' : 'pu-ht'"
                  class="input-modal-champ"
                >
                  <span v-if="annex" class="d-flex ">
                    <font-awesome-icon
                      v-if="
                        product.qte_apres_avoir != null && !product.disabled
                      "
                      icon="copy"
                      class="mr-1 mt-2"
                      title="Copier/Coller Reste qte à avoir"
                      @click="product.qte_apres = product.qte_apres_avoir"
                    />
                    <b-form-input
                      id="qte-product"
                      v-model="product.qte_apres"
                      :max="product.qte_apres_avoir"
                      type="number"
                      step="0.01"
                      min="0"
                      required
                      :disabled="product.disabled"
                    ></b-form-input>
                  </span>

                  <b-form-input
                    v-else
                    autocomplete="off"
                    :id="'pu-ht'"
                    v-model="product.pu_ht"
                    type="number"
                    step="0.01"
                    min="0"
                    required
                    :disabled="product.disabled || annex"
                  ></b-form-input>
                  <div
                    class="error-message d-flex justify-content-center"
                    v-if="product.qte_apres_avoir != null && annex"
                  >
                    <div class="error">
                      Reste qte à avoir
                      {{
                        (
                          Math.round(product.qte_apres_avoir * 100) / 100
                        ).toFixed(2) | FormateValue
                      }}
                    </div>
                  </div>
                </b-form-group></b-col
              >

              <b-col class="col-2">
                <b-form-group
                  label=""
                  label-for="unite-product"
                  class="input-modal-champ"
                >
                  <b-form-select
                    autocomplete="off"
                    id="unite"
                    v-model="product.unite"
                    value-field="id"
                    text-field="name"
                    :disabled="product.disabled || annex"
                    :options="listUnite"
                    class="b-form-select-raduis"
                  ></b-form-select> </b-form-group></b-col
              ><b-col class="col-2">
                <b-form-group
                  label=""
                  :label-for="annex ? 'pu-ht' : 'qte-product'"
                  class="input-modal-champ"
                >
                  <b-form-input
                    v-if="annex"
                    autocomplete="off"
                    :id="'pu-ht'"
                    v-model="product.pu_ht"
                    type="number"
                    step="0.01"
                    min="0"
                    required
                    :disabled="product.disabled || annex"
                  ></b-form-input>
                  <span v-else class="d-flex ">
                    <font-awesome-icon
                      v-if="
                        product.qte_apres_avoir != null && !product.disabled
                      "
                      icon="copy"
                      class="mr-1 mt-2"
                      title="Copier/Coller Reste qte à avoir"
                      @click="product.qte_apres = product.qte_apres_avoir"
                    />
                    <b-form-input
                      id="qte-product"
                      v-model="product.qte_apres"
                      :max="product.qte_apres_avoir"
                      type="number"
                      step="0.01"
                      min="0"
                      required
                      :disabled="product.disabled"
                    ></b-form-input>
                  </span>
                  <div
                    class="error-message d-flex justify-content-center"
                    v-if="product.qte_apres_avoir != null && !annex"
                  >
                    <div class="error">
                      Reste qte à avoir
                      {{
                        (
                          Math.round(product.qte_apres_avoir * 100) / 100
                        ).toFixed(2) | FormateValue
                      }}
                    </div>
                  </div>
                </b-form-group></b-col
              >
            </b-row>
          </b-col>
          <b-row class="mb-1 mt-1"
            ><b-col class="col-12"
              ><hr class="separate-line col-11 m-0 mb-2 ml-4"/></b-col
          ></b-row>
          <b-row v-if="getProduitsFactureAvoir">
            <b-col class="col-5 text-center  font-weight-bold mt-2 "
              >Total :
            </b-col>
            <b-col class="col-2 text-center mb-2 font-weight-bold mt-2 ">
              {{ computedSumProductInput | FormateValue }}</b-col
            >
            <b-col class="col-2"></b-col> <b-col class="col-2"></b-col>
          </b-row>
          <div class="form-actions mt-1 mb-1 text-aligne-center">
            <div class="actionModel">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)" class="mb-0">
                    <li
                      v-for="(e, index) in error"
                      :key="index"
                      style="color: red; list-style: none"
                    >
                      {{ e }}
                    </li>
                  </ul>
                  <pre v-else style="color: red">{{ error }}</pre>
                </div>
              </div>
            </div>
            <b-button
              type="submit"
              class="button-valide-style mt-1"
              size="sm"
              variant="success"
              :disabled="computedDisabledButton"
            >
              Valider
              <div v-if="loadingCreate" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </b-button>
            <div v-if="loading == true" class="loading-custom-template-block">
              <Half-circle-spinner
                :animation-duration="1000"
                :size="50"
                :color="'#4d4bac'"
                class="loeder"
              />
            </div>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    permission: { required: true },
    item: { required: true },
    index: { required: true },
    annex: { required: false, default: true }
  },
  data() {
    return {
      listUnite: [
        { id: '', name: '' },
        { id: '%', name: '%' }
      ],
      loading: false,
      loadingCreate: false,
      error: [],
      disabledButton: false
    };
  },
  computed: {
    ...mapGetters(['getProduitsFactureAvoir']),
    computedSumProductInput() {
      if (this.getProduitsFactureAvoir.length) {
        return this.getProduitsFactureAvoir
          .map(i => parseFloat(i.qte_apres))
          .reduce((a, b) => parseFloat(a) + parseFloat(b))
          .toFixed(2);
      }
      return '';
    },
    computedSocieteColor() {
      return function(product) {
        let array = [
          ...new Set(this.getProduitsFactureAvoir.map(i => i.description))
        ];
        let index = array.indexOf(product.description);
        if (index >= 0 && index % 2 == 0) {
          return 'bg-block-Societe';
        }
        return 'bg-block-Societe-2';
      };
    },
    computedSociete() {
      return function(product, index) {
        return (
          (this.getProduitsFactureAvoir[index - 1] &&
            product.description !==
              this.getProduitsFactureAvoir[index - 1].description) ||
          index == 0
        );
      };
    },
    computedDisabledButton() {
      let products = this.getProduitsFactureAvoir.filter(
        item => item.disabled == false
      );
      if (products.length == 0) {
        return true;
      }
      return false;
    },
    computedDisabledInputGlobale() {
      let products = this.getProduitsFactureAvoir.filter(
        item => item.disabled == false
      );
      if (products.length == 0) {
        return true;
      }
      return false;
    }
  },
  filters: {
    FormateValue: x => {
      {
        // return new Intl.NumberFormat().format(x);
        if (x != null && x != undefined) {
          if (Math.sign(x) === -1) {
            var y = Math.abs(x);
            var parts = y.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            return '-' + parts.join('.');
          } else {
            var parts = x.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
            return parts.join('.');
          }
        }
      }
    }
  },
  methods: {
    ...mapActions(['createAvoirFacture', 'getProductsAvoirFacture']),
    async openModal(ref) {
      this.$refs[ref].show();
      this.loading = true;
      const response = await this.getProductsAvoirFacture(this.item.id);

      if (response.success) {
        this.getProduitsFactureAvoir.map(item => (item.qte_apres = 0));
        this.loading = false;
      }
    },

    hideModal(ref) {
      this.error = [];
      this.loading = false;
      this.loadingCreate;
      this.disabledButton = false;
      this.$refs[ref]?.hide();
    },

    async handleCreateAvoir() {
      this.loadingCreate = true;
      let products = this.getProduitsFactureAvoir.filter(
        item => item.disabled == false && parseFloat(item.qte_apres) != 0
      );
      if (products.length == 0) {
        this.loadingCreate = false;
        this.error =
          'Aucune facture avoir à générer \n Les quantités à avoir doit être supèrieur à 0';
      } else {
        this.error = null;
        let bodyFormData = {
          vendeur: this.item.vendeur_id,
          id_facture: this.item.id,
          produits: products
        };
        const response = await this.createAvoirFacture({
          item: this.item,
          bodyFormData: bodyFormData
        });
        if (response.success) {
          this.loadingCreate = false;
          this.hideModal('AvoirFacture' + this.index);
        } else {
          this.loadingCreate = false;
          this.error = response.error;
        }
      }
    }
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.avoir-facture-partiel {
  //   position: absolute;
  right: 11px;
  display: flex;
}
.scrol-block-product {
  overflow-y: auto;
  height: calc(100vh - 225px);
}
</style>
<style lang="scss">
.button-export-style-avoir {
  color: #e4261b;
  &:hover,
  &:focus {
    color: #e4261b;
  }
}
.cutsom-modal-bootstrap-frais .modal-dialog {
  top: 1% !important;
  max-width: 60% !important;
  margin: auto;
  #info-wrap {
    overflow: hidden;
  }
  .type-doc-class > div {
    display: flex;
  }
}

@media screen and (max-width: 925px) {
  .cutsom-modal-bootstrap-frais .modal-dialog {
    top: 1%;
    max-width: 60%;
    margin: auto;
  }
}
.bg-block-Societe {
  background: #4d4bac21;
}
.bg-block-Societe-2 {
  background: #d2d2ef21;
}
</style>
